<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true",
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :workerFilterShow="true",
          :dateRangeFilterShow="true",
          @changeFilter="updateFilter"
        )
        
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#50b692"
        )

  div(v-else)
    b-row(v-if="!workersTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_workers_found') }}
            

    div(v-else)
      b-row
        b-col
          statistic-card-adherence(:statistic="adherenceTotal")
        b-col
          statistic-card-adjusted(:statistic="adherenceAdjusted")
        b-col
          statistic-card-commitments(:statistic="commitmentsGenerated")
        b-col
          statistic-card-compliance(:statistic="commitmentCompliance")
        b-col
          statistic-card-not-generated(:statistic="commitmentsNotGenerated")

      b-row
        b-col
          bar-chart-commitments(
            v-if="commitmentsData.labels && commitmentsData.labels.length",
            :commitments-data="commitmentsData"
          )

      b-row
        b-col
          bar-chart-risks(
            v-if="risksData.labels && risksData.labels.length",
            :risks-data="risksData"
          )

      //- b-card
        b-table-simple(hover, small, caption-top, responsive)
            caption
              b-card-sub-title(class="mb-2") {{ $t('failedBehaviorsTitle') }} 
            b-thead(head-variant="light")
              b-tr
                th {{ $t('message.tableHeader.worker') }}
                th {{ $t('message.tableHeader.creationDate') }}
                th {{ $t('message.tableHeader.process') }}
                th {{ $t('message.tableHeader.behaviour') }}
                th {{ $t('message.tableHeader.resolutionDate') }}
                th {{ $t('message.tableHeader.status') }}
                th {{ $t('message.tableHeader.commitments') }}
                th {{ $t('message.tableHeader.action') }}
              b-tr(v-for="(behaviour, index) in behaviourList", :key="index")
                b-td {{ behaviour.worker }}
                b-td {{ behaviour.creationDate }}
                b-td {{ behaviour.process }}
                b-td {{ behaviour.behaviourReformatted }}
                b-td {{ behaviour.resolutionDate }}
                b-td(:class="{'bold': true, 'text-success': behaviour.status === 'success', 'text': behaviour.status === 'failed', 'text-warning': behaviour.status === 'pending', 'text-danger': behaviour.status === 'overdue', 'text': behaviour.status === ''}") {{ behaviour.translatedStatus.value }}
                b-td {{ behaviour.commitmentsCount }}
                b-td
                  view-button(v-if="behaviour.status === 'success' || behaviour.status === 'failed'" variant="primary" @clicked="router.push({ name: 'habit-commitment-view', params: { commitmentId: behaviour.commitments[0]._id } })")
                  edit-button(v-if="behaviour.status === 'pending' || behaviour.status === 'overdue'" @clicked="router.push({ name: 'habit-commitment-new', params: { commitmentId: behaviour.commitments[0]._id, workerId: behaviour.workerId } })") 
                  navigation-button(v-if="behaviour.status === '' || behaviour.status === 'failed'" @clicked="openCommitmentSidebar(behaviour)")
      
      b-card
        b-table(
          :items="behaviourList"
          :fields="tableHeaders"
          hover
          responsive
        )
          template(#cell(behaviour)="data")
            span {{ data.item.behaviourReformatted }}
          template(#cell(answer)="data")
            span {{ data.item.answerReformatted.value }}
          template(#cell(expectedAnswer)="data")
            span {{ data.item.expectedAnswerReformatted.value }}
          template(#cell(status)="data")
            span(:class="{'bold': true, 'text-success': data.item.status === 'success', 'text-warning': data.item.status === 'pending', 'text': data.item.status === ''}")
              | {{ data.item.translatedStatus.value }}
          template(#cell(actions)="data")
            div(class="flex flex-column")
              view-button(
                v-if="data.item.status === 'success'"
                variant="primary"
                @clicked="router.push({ name: 'organization-case-view', params: { id: data.item.cases[0]._id } })"
              )
              edit-button(
                v-if="data.item.status === 'pending'"
                @clicked="router.push({ name: 'organization-case-edit', params: { id: data.item.cases[0]._id } })"
                i18nHoverVariable="Execute"
              ) 
              navigation-button(v-if="data.item.status === ''" @clicked="openImprovementSidebar(data.item)")

      todo-task-handler-sidebar(
        v-model="isImprovementHandlerSidebarActive"
        :task="improvement"
        :clear-task-data="clearImprovementData"
        @add-task="addImprovement"
      )
</template>

<script>
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api";
import axios from "@axios";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BCardSubTitle } from "bootstrap-vue";
import Filters from "@/views/organization/Filters.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import i18n from '@/libs/i18n'
import store from "@/store";
import StatisticCardAdherence from './charts/StatisticCardVerticalAdherence.vue';
import StatisticCardAdjusted from './charts/StatisticCardVerticalAdjusted.vue';
import StatisticCardCommitments from './charts/StatisticCardVerticalCommitments.vue';
import StatisticCardCompliance from './charts/StatisticCardVerticalCompliance.vue';
import StatisticCardNotGenerated from './charts/StatisticCardVerticalNotGenerated.vue';
import BarChartCommitments from './charts/ChartjsHorizontalBarChartCommitments';
import BarChartRisks from './charts/ChartjsHorizontalBarChartRisks';
import useCommon from '@/views/organization/useCommon'
import useCommonDashboards from '@/views/habit/useCommonDashboards';
import { queryCommitmentReport } from "@/@core/queries/commitments";
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue';
import { useRouter } from '@core/utils/utils';
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import useNotifications from '@/composables/useNotifications'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import { EventBus } from "@/views/habit/EventBus.js"

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BCardSubTitle,
    Filters, 
    Loading,
    StatisticCardAdherence,
    StatisticCardAdjusted,
    StatisticCardCommitments,
    StatisticCardCompliance,
    StatisticCardNotGenerated,
    BarChartCommitments,
    BarChartRisks,
    ViewButton,
    EditButton,
    TodoTaskHandlerSidebar,
    NavigationButton
  },
  setup() {    
    const userData = store.state?.userStore?.userData
    const userId = userData.worker_id?.$oid
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { handleError, createCase, formatDate } = useCommon()
    const { processAdherence, getDatesFromRange, dateFromObjectId } = useCommonDashboards()
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { route, router } = useRouter()
    const workersTotal = ref(0)
    const isLoading = ref(true)

    const userLocations = userData.locations?.length ? userData.locations : null;
    const locationFilter = ref([]);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const workerFilter = ref([]);

    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)

    const adherenceTotal = ref(0);
    const adherenceAdjusted = ref(0)
    const commitmentsGenerated = ref(0)
    const commitmentCompliance = ref(0)
    const commitmentsNotGenerated = ref(0)
    const commitmentsData = ref({})
    const risksData = ref({})
    const behaviourList = ref([])

    // Table Headers
    const tableHeaders = computed(() => {
      return [
        {
          key: 'worker',
          label: i18n.t('message.tableHeader.worker'),
          sortable: true,
        },
        {
          key: 'process',
          label: i18n.t('message.tableHeader.process'),
          sortable: true,
        },
        {
          key: 'behaviour',
          label: i18n.t('message.tableHeader.behaviour'),
          sortable: true,
        },
        {
          key: 'answer',
          label: i18n.t('message.tableHeader.answer'),
          sortable: true,
        },
        {
          key: 'expectedAnswer',
          label: i18n.t('message.tableHeader.expected'),
          sortable: true,
        },
        {
          key: 'status',
          label: i18n.t('message.tableHeader.status'),
          sortable: true,
        },
        {
          key: 'creationDate',
          label: i18n.t('message.tableHeader.creationDate'),
          sortable: true,
        },
        {
          key: 'actions',
          label: i18n.t('message.tableHeader.actions'),
        },
      ]
    })

    async function listCommitments() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      const input = {
        clientId,
        userId,
        privileges: userData.role,
        locations: locationFilter.value,
        roles: roleFilter.value,
        processes: processFilter.value,
        workers: workerFilter.value,
        date_gte: startFilter,
        date_lt: endFilter
      }

      axios
        .post("/graphql", {
          query: queryCommitmentReport,
          variables: { input },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
          const workersWithCommitments = data?.data?.CommitmentReport?.commitments || []
          workersTotal.value = workersWithCommitments.length
          parseConfirmationData(workersWithCommitments)
        })
        .catch((error) => {
          console.log(error);
          handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
        })
        .finally(() => {
          isLoading.value = false
        })

    }

    function parseConfirmationData(workersWithCommitments) {
      const labels = []
      const processAdherenceValues = []
      const increasedAdherenceValues = []
      const unresolvedGapValues = []
      const gapWithoutCommitmentValues = []
      const workerIds = []
      const dataByRisk = {}

      let totalScoreCount = 0
      let totalTotalCount = 0
      let adjustedScoreCount = 0
      let unresolvedGapCount = 0
      let commitmentsGeneratedCount = 0
      let commitmentsNotGeneratedCount = 0

      const behaviourListArray = []

      for (const worker of workersWithCommitments) {
        const behavioursFailed = []

        for (const e of worker.confirmationsData) {
          const processCodeAndName = e.processCode
            ? `${e.processCode} / ${e.processName}`
            : e.processName
 
          if (dataByRisk.hasOwnProperty(processCodeAndName)) {
              dataByRisk[processCodeAndName].score += e.score
              dataByRisk[processCodeAndName].total += e.total
            } else dataByRisk[processCodeAndName] = {
              score: e.score,
              total: e.total,
              increasedAdherence: 0,
              unresolvedGap: 0,
              id: e.processId
            }
          
          if (worker.averageAdherence === 1) continue

          e.activities?.forEach(a => {
            a.behaviours?.forEach(b => {
              if (b.type === "bool" && (b.answer === "true" && !b.expectedAnswer) || (b.answer === "false" && b.expectedAnswer)) {
                behavioursFailed.push({
                  activity: a.name,
                  behaviour: b.name
                })
                behaviourListArray.push({
                  worker: worker.name,
                  workerId: worker._id,
                  process: processCodeAndName,
                  activity: a.name,
                  behaviour: b.name,
                  behaviourReformatted: b.name?.replace(/<[^>]*>/g, '').slice(0, 50) + "...",
                  answer: b.answer,
                  expectedAnswer: b.answer === 'true' ? 'false' : 'true',
                  answerReformatted: computed(() => i18n.t(`message.${b.answer === 'true' ? 'yes' : 'no'}`)),
                  expectedAnswerReformatted: computed(() => i18n.t(`message.${b.answer === 'true' ? 'no' : 'yes'}`)),
                  type: b.type,
                  confirmationId: e._id,
                  cases: e.cases?.filter(c => c.activity === a.name && c.behaviour?.name === b.name),
                })                  
              }
            })
          })
          
          e.cases?.forEach(c => {
            for (const behaviourFailed of behavioursFailed) {
              if (behaviourFailed.activity === c.activity && behaviourFailed.behaviour === c.behaviour?.name) {
                if (c.stage === 'end') {
                  behaviourFailed.status = "success"
                  dataByRisk[processCodeAndName].increasedAdherence++
                } else {
                  behaviourFailed.status = "pending"
                  dataByRisk[processCodeAndName].unresolvedGap++
                }
                break
              }
            }
          })
        }

        const increasedAdherence = behavioursFailed.filter(e => e.status === "success").length
        const unresolvedGap = behavioursFailed.filter(e => e.status === "pending").length
        const gapWithoutCommitment = worker.totalTotal - worker.totalScore - increasedAdherence - unresolvedGap

        labels.push(worker.name)
        processAdherenceValues.push(processAdherence(worker.totalScore, worker.totalTotal))
        increasedAdherenceValues.push(processAdherence(increasedAdherence, worker.totalTotal))
        unresolvedGapValues.push(processAdherence(unresolvedGap, worker.totalTotal))
        gapWithoutCommitmentValues.push(processAdherence(gapWithoutCommitment, worker.totalTotal))
        workerIds.push(worker._id)

        totalScoreCount += worker.totalScore
        totalTotalCount += worker.totalTotal
        adjustedScoreCount += increasedAdherence
        unresolvedGapCount += unresolvedGap
        commitmentsGeneratedCount += increasedAdherence + unresolvedGap
        commitmentsNotGeneratedCount += behavioursFailed.length - increasedAdherence - unresolvedGap
      }

      adherenceTotal.value = `${processAdherence(totalScoreCount, totalTotalCount)}%`
      adherenceAdjusted.value = `${processAdherence(totalScoreCount + adjustedScoreCount, totalTotalCount)}%`
      commitmentsGenerated.value = commitmentsGeneratedCount
      commitmentCompliance.value = `${processAdherence(adjustedScoreCount, adjustedScoreCount + unresolvedGapCount)}%`
      commitmentsNotGenerated.value = commitmentsNotGeneratedCount

      commitmentsData.value = {
        labels: labels.slice(0,10),
        data: {
          processAdherenceValues: processAdherenceValues.slice(0, 10),
          increasedAdherenceValues: increasedAdherenceValues.slice(0, 10),
          unresolvedGapValues: unresolvedGapValues.slice(0, 10),
          gapWithoutCommitmentValues: gapWithoutCommitmentValues.slice(0, 10)
        },
        additionalInfo: {
          workerIds
        }
      }

      behaviourListArray.forEach(b => {
          const count = b.cases?.length
          let status = ''
          let dueDate = ''
          let creationDate = null
          if (count > 0) {
            const commitmentToShow = b.cases[0]
            creationDate = commitmentToShow.createdAt ? new Date(commitmentToShow.createdAt) : ''
            dueDate = new Date(commitmentToShow.dueDate)
            status = commitmentToShow.stage === 'end' ? 'success' : 'pending'
          }
                    
          const translatedStatus = status === 'success'
            ? computed(() => i18n.t('label.resolved'))
            : status === 'pending'
              ? computed(() => i18n.t('pending'))
              : computed(() => i18n.t('label.not_generated'))
          
          b.creationDate = creationDate ? creationDate.toLocaleDateString(`${default_language || 'en'}-US`) : '-'
          b.status = status
          b.translatedStatus = translatedStatus
      })

      behaviourList.value = behaviourListArray

      // Data for risk chart
      for (const risk in dataByRisk) {
        dataByRisk[risk].adherence = processAdherence(dataByRisk[risk].score, dataByRisk[risk].total)
        dataByRisk[risk].gapWithoutCommitment = dataByRisk[risk].total - dataByRisk[risk].score - dataByRisk[risk].increasedAdherence - dataByRisk[risk].unresolvedGap
      }

      const dataSorted = Object.entries(dataByRisk)

      dataSorted.sort(function (a, b) {
        return a[1].adherence - b[1].adherence
      })

      const limit = Math.min(dataSorted.length, 10)

      const riskLabels = []
      const riskAdherenceValues = []
      const riskIncreasedAdherenceValues = []
      const riskUnresolvedGapValues = []
      const riskGapWithoutCommitmentValues = []
      const riskIds = []
      const riskLabelsComplete = []

      for (let i = 0; i < limit; i++) {
        riskLabels.push(dataSorted[i][0].length > 24 ? dataSorted[i][0].slice(0, 24) + "..." : dataSorted[i][0])
        riskAdherenceValues.push(dataSorted[i][1].adherence)
        riskIncreasedAdherenceValues.push(processAdherence(dataSorted[i][1].increasedAdherence, dataSorted[i][1].total))
        riskUnresolvedGapValues.push(processAdherence(dataSorted[i][1].unresolvedGap, dataSorted[i][1].total))
        riskGapWithoutCommitmentValues.push(processAdherence(dataSorted[i][1].gapWithoutCommitment, dataSorted[i][1].total))
        riskIds.push(dataSorted[i][1].id)
        riskLabelsComplete.push(dataSorted[i][0])
      }

      risksData.value = {
        labels: riskLabels,
        data: {
          processAdherenceValues: riskAdherenceValues,
          increasedAdherenceValues: riskIncreasedAdherenceValues,
          unresolvedGapValues: riskUnresolvedGapValues,
          gapWithoutCommitmentValues: riskGapWithoutCommitmentValues
        },
        additionalInfo: {
          riskIds: riskIds,
          completeLabels: riskLabelsComplete,
        }
      }
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      dateRangeFilter.value = data.dateRangeFilter;

      listCommitments();    
    }

    onMounted(() => {
      if (route.value.query.workerId) {
        EventBus.$emit("filterByWorker", route.value.query.workerId)
      } else {
        listCommitments()
      }
    })

    // Improvement sidebar
    const isImprovementHandlerSidebarActive = ref(false)

    const blankImprovement = {
      client_id: { link: clientId },
      note: '',
      assignee: '',
      createdAt: now,
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      activity: '',
      behaviour: {
        name: '',
        type: '',
      },
      // objective: '',
    }

    const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))

    const clearImprovementData = () => {
      improvement.value = JSON.parse(JSON.stringify(blankImprovement))
    }

    const openImprovementSidebar = (behaviour) => {
      improvement.value = {
        ...improvement.value,
        activity: behaviour.activity,
        behaviour: {
          name: behaviour.behaviour,
          type: behaviour.type,
        },
        confirmation: behaviour.confirmationId
      }
      isImprovementHandlerSidebarActive.value = true
    }

    const addImprovement = async (improvementData) => {
      isLoading.value = true
      try {
        improvementData.assignee = { link: improvementData.assignee }
        improvementData.confirmation = improvementData.confirmation ? { link: improvementData.confirmation } : null
        improvementData.proposalResponsible = { link: improvementData.assignee.link }
        improvementData.approvalResponsible = improvementData.approvalResponsible ? { link: improvementData.approvalResponsible } : null
        improvementData.verificationResponsible = improvementData.verificationResponsible ? { link: improvementData.verificationResponsible } : null
        improvementData.dueDate = improvementData.dueDate ? formatDate(improvementData.dueDate) : null
        improvementData.proposalDueDate = improvementData.proposalDueDate ? formatDate(improvementData.proposalDueDate) : null
        improvementData.approvalDueDate = improvementData.approvalDueDate ? formatDate(improvementData.approvalDueDate) : null
        improvementData.verificationDueDate = improvementData.verificationDueDate ? formatDate(improvementData.verificationDueDate) : null
        improvementData.associatedCases = improvementData.associatedCases ? { link: improvementData.associatedCases } : null
        improvementData.areas = improvementData.areas ? { link: improvementData.areas } : null
        improvementData.stage = 'analysis'
        improvementData.lastModifiedBy = userId ? { link: userId } : null,
        await createCase(improvementData)
        showSuccessMessage(i18n.t('message.improvement_created'))
        listCommitments()
      } catch (error) {
        console.log(error)
        isLoading.value = false
      }
    }

    return {
      updateFilter,
      isLoading,
      adherenceTotal,
      adherenceAdjusted,
      commitmentsGenerated,
      commitmentCompliance,
      commitmentsNotGenerated,
      commitmentsData,
      risksData,
      workersTotal,
      behaviourList,
      router,
      tableHeaders,

      // Improvement sidebar
      isImprovementHandlerSidebarActive,
      improvement,
      clearImprovementData,
      openImprovementSidebar,
      addImprovement
    };
  },
};
</script>

<style scoped>
.text {
  color: #c0c0c0
}

.bold {
  font-weight: 600;
}
</style>