<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">{{ $t('commitmentReportByRisk') }}</b-card-title>
        <b-card-sub-title>{{ $t('adherenceProgressToCommitments') }}</b-card-sub-title>
      </div>
    </b-card-header>
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        :height="400"
        :options="horizontalBarChart.options"
        :data="this.series"         
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import ChartjsComponentHorizontalBarChart from '@/views/habit/charts-components/ChartjsComponentHorizontalBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    risksData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      horizontalBarChart: {
        options: {
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
          onClick: function (c, i) {
            let e = i[0];
            if (!e) return
            const riskId = this.data._additionalInfo.riskIds[e._index]
            EventBus.$emit("filterByProcess", riskId);
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: false,
            },
          },
          tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            mode: "point",
            callbacks: {
              title: function(tooltipItem, data) {
                const index = tooltipItem[0].index
                const completeRiskName = data._additionalInfo.completeLabels[index]
                return completeRiskName
              },
              label: function(tooltipItem, data) {
                const datasetIndex = tooltipItem.datasetIndex;
                const category = data.datasets[datasetIndex].label;
                const value = data.datasets[datasetIndex].data[tooltipItem.index];
                return `${category}: ${value}%`;
              }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                  borderColor: 'transparent',
                  color: 'rgba(200, 200, 200, 0.2)',
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                  fontColor: '#6e6b7b',
                  precision: 0,
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
          },
        },
      },
    }
  },
  computed: {
    series() {
      return {
        labels: this.risksData.labels,
        datasets: [
          {
            label: i18n.t('processAdherence'),
            data: this.risksData.data.processAdherenceValues,
            backgroundColor: $themeColors.info,
            borderColor: $themeColors.info,
            barThickness: 20,
          },
          {
            label: i18n.t('increaseAdherence'),
            data: this.risksData.data.increasedAdherenceValues,
            backgroundColor: $themeColors.success,
            borderColor: $themeColors.success,
            barThickness: 20,
          },
          {
            label: i18n.t('unresolvedGap'),
            data: this.risksData.data.unresolvedGapValues,
            backgroundColor: '#eaf6f2',
            borderColor: $themeColors.success,
            barThickness: 20,
          },
          {
            label: i18n.t('gapWithoutCommitment'),
            data: this.risksData.data.gapWithoutCommitmentValues,
            backgroundColor: '#cccccc',
            borderColor: '#cccccc',
            barThickness: 20,
          },
        ],
        _additionalInfo: {
          riskIds: this.risksData.additionalInfo.riskIds,
          completeLabels: this.risksData.additionalInfo.completeLabels
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>